<template>
  <v-dialog
    v-model="dialog"
    width="600"
    scrollable
  >
    <v-card>
      <v-card-title>
        Buscar vendedor
        <v-spacer></v-spacer>
        <div class="mr-4">
          <SearchDataTable
            v-model="search"
            autofocus
          />
        </div>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-data-table
          class="cebra"
          sort-by="nombre"
          :search="search"
          :items="p_lista"
          :headers="headers"
          dense
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              color="success"
              title="Seleccionar"
              small
              @click="$emit('selected', item); dialog = false"
            >
              fas fa-check
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="350"
              type="warning"
              color="orange"
              border="left"
              dense
              text
            >
              No se encontraron vendedores
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import SearchDataTable from '../../util/SearchDataTable.vue'

export default {
  data () {
    return {
      search: '',
      headers: [
        { text: 'Código', value: 'codigo', align: 'end' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false, align: 'center' }
      ]
    }
  },
  props: {
    value: Boolean,
    p_lista: Array
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    SearchDataTable
  },
  watch: {
    dialog (val) {
      val || (this.search = '')
    }
  }
}
</script>