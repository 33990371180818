<template>
  <v-dialog
    v-model="dialog"
    width="700"
    :persistent="load"
    scrollable
  >
    <v-card>
      <v-card-title style="word-break: normal;">
        Enviar Gift Card al Cliente {{ p_venta.cliente }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-expand-transition>
          <div v-if="load" class="px-6 pb-5">
            <div class="py-3" style="font-size: 18px;">
              Obteniendo Gift Card, por favor espere...
            </div>
            <v-progress-linear
              color="primary"
              class="mb-0"
              indeterminate
            ></v-progress-linear>
          </div>
        </v-expand-transition>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-row class="d-flex justify-end pa-3">
          <v-btn
            color="info"
            class="mb-sm-0 mb-2"
            :disabled="load"
            @click="enviarEmail()"
          >
            <v-icon left>fas fa-envelope</v-icon>
            Enviar Email
          </v-btn>
          <download-excel
            :data="giftcard"
            :name="`Cupones para la Venta ${p_venta.id}.xls`"
            :fields="{
              'Monto': 'importe',
              'Destinatario': 'titular',
              'Teléfono': 'telefono',
              'Cupón': 'cupon'
            }"
          >
            <v-btn
              color="success"
              class="mb-sm-0 mb-2 ml-2"
              :disabled="load"
            >
              <v-icon left>fas fa-file-excel</v-icon>
              Descargar Excel
            </v-btn>
          </download-excel>
          <v-btn
            color="wpp"
            class="mb-sm-0 mb-2 ml-2"
            :disabled="load"
            :dark="!load"
            @click="sendWpp()"
          >
            <v-icon left>fab fa-whatsapp</v-icon>
            WhatsApp
          </v-btn>
          <v-btn
            color="error"
            class="mb-sm-0 mb-2 ml-2"
            @click="dialog = false"
          >
            Cancelar
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      load: false,
      giftcard: []
    }
  },
  props: {
    value: Boolean,
    p_venta: Object
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    async dialog (val) {
      if (val) {
        if (Object.keys(this.p_venta).length > 0) {
          this.load = true
          let result = await this.$store.dispatch('ventas/info_gf', this.p_venta.id)
          this.load = false

          if (result.exito = 1) {
            this.giftcard = result.data
          } else {
            this.dialog = false
            this.$swal.fire({
              icon: 'error',
              title: result.message
            })
          }
        } else {
          this.dialog = false
        }
      } else {
        this.giftcard = []
      }
    }
  },
  methods: {
    async enviarEmail () {
      this.$store.state.loading_texto = 'Enviando email de Gift Card ...'
      this.$store.state.loading_ultra = true
      let result = await this.$store.dispatch('ventas/enviar_email_gf', { venta_id: this.p_venta.id })
      this.$store.state.loading_ultra = false

      if (result.resultado == 1) {
        this.$swal.fire({
          icon: 'success',
          title: 'El email fue enviado correctamente'
        })
      } else {
        this.$swal.fire({
          icon: 'error',
          title: result.message
        })
      }
    },
    sendWpp () {
      if (this.giftcard.length > 0) {
        window.open(`https://api.whatsapp.com/send?phone=${this.giftcard[0].telefono}`, '_blank')
      }
    }
  }
}
</script>