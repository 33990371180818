<template>
    <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!-- FILTROS -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            small
            fab
            @click="item_excep = {}; dialog = true"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel == 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Artículo Base
                  <v-text-field
                    v-model.trim="articulo"
                    type="number"
                    tabindex="1"
                    hide-details
                    clearable
                    outlined
                    dense
                    @change="getFinanciaciones()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-1">
                  Forma de Pago
                  <v-autocomplete
                    v-model="filtro.fp_cond_id"
                    item-text="forma_pago_nombre"
                    item-value="forma_pago_id"
                    tabindex="1"
                    :items="articulo ? financiaciones : all_financiaciones"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Nº Documento
                  <v-text-field
                    v-model.trim="filtro.documento"
                    type="number"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Artículo Código
                  <v-text-field
                    v-model.trim="filtro.articulo"
                    type="number"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="4" md="2" class="py-1">
                  Estado
                  <v-autocomplete
                    v-model="filtro.inhabilitado"
                    tabindex="1"
                    :items="estados"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <BtnFiltro
                  :loading="load"
                  clase="mt-0"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- DATA TABLE -->
      <v-data-table
        class="cebra elevation-2 mt-2"
        sort-by="id"
        :items="excepciones"
        :headers="headers"
        :loading="load"
        :search="search"
        sort-desc
        dense
      >
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="3" xl="2">
              <SearchDataTable
                v-model="search"
              />
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.inhabilitado`]="{ value }">
          {{ value == 1 ? 'IN' : '' }}ACTIVO
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            color="info"
            title="Ver"
            class="mr-2"
            small
            @click="item_excep = item; dialog = true"
          >
            fas fa-eye
          </v-icon>
          <v-icon
            v-if="item.venta_id"
            color="purple"
            title="Ver Venta"
            class="mr-2"
            small
            @click="openWindow(item.venta_id)"
          >
            fas fa-shopping-cart
          </v-icon>
          <BtnConfirmar
            v-if="item.inhabilitado != 1"
            clase="mr-2"
            icono="fas fa-ban"
            color="error"
            title="Inhabilitar"
            :texto="`¿Desea inhabilitar la autorización <strong>${item.descripcion}</strong> para <strong>${item.username}</strong>?`"
            icon_button
            small
            @action="inhabilitar(item)"
          />
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <!-- COMPONENTES -->
    <ModalExcepcion
      v-model="dialog"
      :p_excepcion="item_excep"
      :p_financiaciones="all_financiaciones"
      @actualizar="actualizarDatos"
    />
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import { get_nombre_obj_arr, order_list_by } from '../../util/utils'
import BtnFiltro from '../../components/util/BtnFiltro.vue'
import BtnConfirmar from '../../components/util/BtnConfirmar.vue'
import SearchDataTable from '../../components/util/SearchDataTable.vue'
import ModalExcepcion from '../../components/ventas/ModalExcepcion.vue'

export default {
  data () {
    return {
      panel: 0,
      load: false,
      dialog: false,
      articulo_nombre: '',
      search: '',
      articulo: '',
      all_financiaciones: [],
      financiaciones: [],
      excepciones: [],
      headers: [
        { text: 'Forma Pago', value: 'descripcion' },
        { text: 'Usuario', value: 'username' },
        { text: 'Estado', value: 'inhabilitado' },
        { text: 'N° Documento', value: 'documento' },
        { text: 'N° Pedido', value: 'venta_id' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false }
      ],
      estados: [
        { value: 0, text: 'ACTIVO' },
        { value: 1, text: 'INACTIVO' }
      ],
      item_excep: {},
      filtro: {
        fp_cond_id: null,
        documento: null,
        articulo: null,
        inhabilitado: null
      }
    }
  },
  async created () {
    this.$store.state.loading = true
    // obtengo la configuracion del usuario
    await this.$store.dispatch('financiaciones/get_conf_user')
    this.$store.state.loading = false
    // obtengo las financiaciones
    await this.getFinanciaciones()
  },
  computed: {
    ...mapState('financiaciones', ['confUsuario'])
  },
  components: {
    BtnFiltro,
    BtnConfirmar,
    SearchDataTable,
    ModalExcepcion
  },
  methods: {
    actualizarDatos (data, articulos) {
      let excepcion = this.excepciones.find(ex => ex.id == data.id)
      if (!excepcion) return

      excepcion.username = data.username
      excepcion.fp_cond_id = data.fp_cond_id
      excepcion.descripcion = get_nombre_obj_arr(this.financiaciones, 'forma_pago_id', data.fp_cond_id, 'forma_pago_nombre')
      excepcion.documento = data.documento ? data.documento : null
      excepcion.inhabilitado = data.inhabilitado
      excepcion.articulos = [...articulos]
    },
    async inhabilitar (item) {
      this.$store.state.loading = true
      let result = await this.$store.dispatch('financiaciones/inhabilitar_excepcion', item.id)
      this.$store.state.loading = false

      if (result.exito == 1) {
        item.inhabilitado = 1
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'success'
        })
      } else {
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }
    },
    async buscar () {
      this.excepciones = []
      this.search = ''

      this.load = true
      let result = await this.$store.dispatch('financiaciones/get_excepciones', this.filtro)
      this.load = false

      if (result.exito == 1) {
        this.excepciones = result.data
      } else {
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }
    },
    async getFinanciaciones () {
      this.financiaciones = []
      this.filtro.fp_cond_id = null
      if (!this.articulo && this.all_financiaciones.length > 0) return
      this.$store.state.loading = true
      await this.$store.dispatch('financiaciones/get_financiaciones_condcom', {
        articulo: this.articulo ? this.articulo : 0,
        especial: 1,
        empresas: this.confUsuario.empresas,
        locales: this.confUsuario.locales,
        sucursales: this.confUsuario.sucursales,
        canales: this.confUsuario.canales
      })
        .then((res) => {
          if (this.articulo) {
            this.financiaciones = res.formas_pago_habilitadas
          } else {
            this.all_financiaciones = res.formas_pago_habilitadas
          }
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
      order_list_by(this.financiaciones, 'forma_pago_nombre')
      order_list_by(this.all_financiaciones, 'forma_pago_nombre')
    },
    limpiar () {
      this.filtro = {
        fp_cond_id: null,
        documento: null,
        articulo: null,
        inhabilitado: null
      }
    },
    openWindow (id_venta) {
      const routeData = this.$router.resolve({ path: `/venta-salon/${id_venta}` })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>