<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!-- FILTRO -->
      <v-form @submit.prevent="buscar()">
        <v-row>
          <v-col cols="6" sm="4" md="3" xl="2">
            Nº Pedido
            <v-text-field
              v-model.trim="num_ped"
              hide-details
              autofocus
              clearable
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="6" sm="4" md="3" xl="2" class="py-0">
            <v-btn
              class="mt-8 ml-4"
              type="submit"
              color="info"
            >
              <v-icon left>fas fa-search</v-icon>
              Buscar
            </v-btn>
          </v-col>
          <v-col cols="12" sm="4" md="6" xl="8" class="d-flex justify-sm-end align-end py-1 pl-sm-0">
            <v-icon style="padding-bottom: 2px" left>fas fa-store</v-icon>
            <strong>{{ local }}</strong>
          </v-col>
        </v-row>
      </v-form>
      <!-- SOLICITUDES COBRO -->
      <v-data-table
        v-model="selected"
        class="cebra elevation-2 mt-6"
        sort-by="id"
        :headers="headers_sol"
        :items="solicitudes"
        :items-per-page="-1"
        hide-default-footer
        single-select
        show-select
        dense
        @click:row="rowClick"
      >
        <template v-slot:top>
          <div class="pt-2 ml-2">
            Solicitudes {{ local ? 'del pedido Nº ' + num_ped : '' }}
          </div>
        </template>
        <template v-slot:[`item.estado`]="{ item }">
          <v-chip
            :color="item.color"
            small
          >
            {{ item.estado }}
          </v-chip>
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          {{ formatMoney(item.importe) }}
        </template>
        <template v-slot:[`item.fp_tar`]="{ item }">
          {{ item.tarjeta }} {{ item.forma_pago }}
        </template>
        <template v-slot:[`item.qr`]="{ item }">
          <v-icon
            v-if="item.qr == 1"
            color="success"
            small
          >
            fas fa-check-circle
          </v-icon>
        </template>
        <template v-slot:[`item.log`]="{ item }">
          <v-tooltip color="primary" left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                color="info"
                small
              >
                fas fa-info-circle
              </v-icon>
            </template>
            <div>
              <strong style="margin-right: 14px">Grabación:</strong>{{ item.log }}
            </div>
            <div>
              <strong class="mr-2">Log Estado:</strong>{{ item.estado_log }}
            </div>
          </v-tooltip>
        </template>
      </v-data-table>
      <!-- DETALLE TRANSACCIONES -->
      <v-data-table
        class="cebra elevation-2 mt-4"
        sort-by="id"
        :headers="headers"
        :items="transacciones"
        :items-per-page="-1"
        hide-default-footer
        dense
      >
        <template v-slot:top>
          <div class="pt-2 ml-2">
            Transacciones {{ selected.length > 0 ? 'de la solicitud ' + selected[0].id : '' }}
          </div>
        </template>
        <template v-slot:[`item.estado`]="{ item }">
          <v-chip
            :color="item.color"
            small
          >
            {{ item.estado }}
          </v-chip>
        </template>
        <template v-slot:[`item.payment_id`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                color="info"
                small
              >
                fas fa-eye
              </v-icon>
            </template>
            <span>{{ item.payment_id }}</span>
          </v-tooltip>
          <v-btn
            title="Copy to clipboard"
            small
            icon
            @click="copy_clipboard(item.payment_id)"
          >
            <v-icon small>fas fa-copy</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.mensaje`]="{ item }">
          <template v-if="item.mensaje">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="orange"
                  small
                >
                  fas fa-info-circle
                </v-icon>
              </template>
              <span>{{ item.mensaje }}</span>
            </v-tooltip>
            <v-btn
              title="Copy to clipboard"
              small
              icon
              @click="copy_clipboard(item.mensaje)"
            >
              <v-icon small>fas fa-copy</v-icon>
            </v-btn>
          </template>
        </template>
      </v-data-table>
      <!-- ACCIONES -->
      <v-col v-if="permiso">
        <v-row>
          <v-col class="px-0 pt-2">
            <v-btn
              color="info"
              class="mr-3 mt-3"
              :disabled="transacciones.length < 1"
              small
              @click="enviar_peticion('ACTUALIZAR')"
            >
              <v-icon left small>fas fa-sync</v-icon>
              Actualizar
            </v-btn>
            <v-btn
              color="success"
              class="mr-3 mt-3"
              :disabled="!existe || !(selected[0].anulada != 1 || admin)"
              small
              @click="enviar_peticion('ENVIAR_PAGO')"
            >
              <v-icon left small>fas fa-arrow-circle-up</v-icon>
              Enviar pago
            </v-btn>
            <v-btn
              color="pink lighten-1"
              class="mr-3 mt-3"
              :disabled="transacciones.length < 1 || !((selected[0].estado_id == 4 && transacciones[0].estado_id == 10) || admin)"
              :dark="!(transacciones.length < 1 || !((selected[0].estado_id == 4 && transacciones[0].estado_id == 10) || admin))"
              small
              @click="enviar_peticion('CANCELAR_PAGO')"
            >
              <v-icon left small>fas fa-times-circle</v-icon>
              Cancelar pago
            </v-btn>
            <v-btn
              color="orange"
              class="mr-3 mt-3"
              :disabled="transacciones.length < 1 || !(selected[0].anulada == 1 || admin)"
              :dark="!(transacciones.length < 1 || !(selected[0].anulada == 1 || admin))"
              small
              @click="enviar_peticion('ENVIAR_ANULACION')"
            >
              <v-icon left small>fas fa-arrow-circle-up</v-icon>
              Enviar anulación
            </v-btn>
            <v-btn
              color="purple"
              class="mr-3 mt-3"
              :disabled="transacciones.length < 1"
              :dark="transacciones.length >= 1"
              small
              @click="enviar_peticion('CANCELAR_ANULACION')"
            >
              <v-icon left small>fas fa-times-circle</v-icon>
              Cancelar anulación
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
import BtnFiltro from '../../components/util/BtnFiltro'
import { format_money } from '../../util/utils'

export default {
  data () {
    return {
      formatMoney: format_money,
      existe: false,
      permiso: false,
      admin: false,
      local: null,
      num_ped: '',
      headers: [
        { text: 'Id', value: 'id', sortable: false, filterable: false },
        { text: 'Tipo', value: 'tipo', sortable: false, filterable: false },
        { text: 'Estado', value: 'estado', sortable: false, filterable: false },
        { text: 'Payment Id', value: 'payment_id', sortable: false, filterable: false },
        { text: 'Mensaje', value: 'mensaje', sortable: false, filterable: false },
        { text: 'Fecha', value: 'fecha', sortable: false, filterable: false },
        { text: 'Cupón', value: 'cupon', sortable: false, filterable: false },
        { text: 'Lote', value: 'lote', sortable: false, filterable: false },
        { text: 'Cod. Aut.', value: 'codigo_aut', sortable: false, filterable: false },
        { text: 'Terminal', value: 'terminal_id', sortable: false, filterable: false },
        { text: 'QR Id', value: 'qr_id', sortable: false, filterable: false },
        { text: 'Fecha log', value: 'fecha_log', sortable: false, filterable: false },
        { text: 'Usuario log', value: 'usuario_log', sortable: false, filterable: false }
      ],
      headers_sol: [
        { text: 'Id', value: 'id', sortable: false, filterable: false, align: 'right' },
        { text: 'Estado', value: 'estado', sortable: false, filterable: false },
        { text: 'Importe', value: 'importe', sortable: false, filterable: false, align: 'right' },
        { text: 'Nº Tarjeta', value: 'num_tarjeta', sortable: false, filterable: false, align: 'right' },
        { text: 'BIN', value: 'bin', sortable: false, filterable: false, align: 'right' },
        { text: 'Cuotas', value: 'cuotas', sortable: false, filterable: false, align: 'right' },
        { text: 'Financiacion', value: 'fp_tar', sortable: false, filterable: false },
        { text: 'Titular', value: 'titular', sortable: false, filterable: false },
        { text: 'Nº Documento', value: 'num_doc', sortable: false, filterable: false },
        { text: 'QR', value: 'qr', sortable: false, filterable: false, align: 'center' },
        { text: 'Log', value: 'log', sortable: false, filterable: false, align: 'center' }
      ],
      transacciones: [],
      solicitudes: [],
      selected: []
    }
  },
  async created () {
    this.$store.state.loading = true
    const permisos = await this.$store.dispatch('laposIntegrado/get_permisos')
    this.$store.state.loading = false
    this.permiso = permisos.permiso
    this.admin = permisos.admin
  },
  components: {
    BtnFiltro
  },
  watch: {
    num_ped () {
      this.existe = false
      this.local = null
      this.selected = []
      this.solicitudes = []
      this.transacciones = []
    },
    async selected () {
      if (this.selected.length > 0) {
        // obtengo las transacciones
        await this.get_trans(this.selected[0].id)
      }
      else {
        this.existe = false
        this.transacciones = []
      }
    }
  },
  methods: {
    async enviar_peticion (tipo) {

      if (this.selected.length == 0) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar una solicitud de cobro',
          color: 'orange'
        })
      }

      let confirma = false
      await this.$swal.fire({
        icon: 'warning',
        title: `¿Desea ${tipo} ${tipo != 'ACTUALIZAR' ? 'de' : ''}  la última transaccón?`,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        showCancelButton: true
      })
        .then((res) => {
          // si pone que no o hace click fuera del modal no hace nada
          confirma = res.isConfirmed
        })
      if (!confirma) return
      
      let consulta = ''
      switch (tipo) {
        case 'ACTUALIZAR':
          consulta = 'actualizar'
          break
        case 'ENVIAR_PAGO':
          consulta = 'enviar_pago'
          break
        case 'CANCELAR_PAGO':
          consulta = 'cancelar_pago'
          break
        case 'ENVIAR_ANULACION':
          consulta = 'enviar_anular'
          break
        case 'CANCELAR_ANULACION':
          consulta = 'cancelar_anular'
          break
        default:
          return
      }

      this.$store.state.loading = true
      let resultado = await this.$store.dispatch(`laposIntegrado/${consulta}`, this.selected[0].id)
      this.$store.state.loading = false

      if (resultado.exito == 1) {
        this.$store.dispatch('show_snackbar', {
          text: resultado.message,
          color: 'success'
        })
        await this.buscar(this.selected[0].id)
      } else {
        this.$store.dispatch('show_snackbar', {
          text: resultado.message,
          color: 'error'
        })
      }
    },
    async get_trans (sol_cobro_id) {
      this.$store.state.loading = true
      this.existe = false
      this.transacciones = []
      await this.$store.dispatch('laposIntegrado/get_transacciones', sol_cobro_id)
        .then((res) => {
          this.existe = true
          this.transacciones = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async buscar (sol_cobro_id) {
      if (!this.num_ped) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Debe ingresar un número de pedido',
          color: 'orange'
        })
      }
      this.$store.state.loading = true
      this.local = null
      this.existe = false
      if (!sol_cobro_id) { this.selected = [] }
      this.solicitudes = []
      this.transacciones = []
      await this.$store.dispatch('laposIntegrado/get_solicitud', this.num_ped)
        .then(async (res) => {
          this.local = res.data.local
          this.solicitudes = res.data.solicitudes
          // si envio la solicitud, vuelvo a buscarla
          if (sol_cobro_id) { await this.get_trans(this.selected[0].id) }
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    copy_clipboard (texto) {
      navigator.clipboard.writeText(texto)
    },
    rowClick (item, row) {
      let selectState = (row.isSelected) ? false : true
      row.select(selectState)
    }
  }
}
</script>