<template>
  <v-dialog
    v-model="dialog"
    width="800"
    scrollable
  >
    <v-card>
      <v-card-title>
        Buscar Forma de Pago
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-title>
        <v-row>
          <v-col cols="6" sm="4" md="3">
            <text-field-money
              v-model="monto"
              label="Monto"
              v-bind:properties="{
                prefix: '$',
                'hide-details': true
              }"
              @change="searchFP()"
            />
          </v-col>
          <v-col cols="6" sm="4">
            <v-text-field
              v-model.trim="descripcion"
              label="Descripción"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="8" sm="4" md="3">
            <v-checkbox
              v-model="bancos"
              class="mt-0"
              label="Especiales Banco"
              hide-details
              dense
              @change="searchFP()"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-data-table
          class="cebra"
          sort-by="descripcion"
          :search="descripcion"
          :items="financiaciones"
          :headers="headers"
          :item-class="itemRowBackground"
          dense
        >
          <template v-slot:[`item.monto_cuotas`]="{ value }">
            {{ formatMoney(value) }}
          </template>
          <template v-slot:[`item.importe_total`]="{ value }">
            {{ formatMoney(value) }}
          </template>
          <template v-slot:[`item.simulacion`]="{ item }">
            <v-tooltip
              v-if="item.simulacion.length > 0"
              color="primary"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="info"
                  class="mx-n2"
                  small
                >
                  fas fa-info-circle
                </v-icon>
              </template>
              <div
                v-for="(sim, index) in item.simulacion"
                :key="index"
                style="width: 600px;"
              >
                <v-row style="width: 100%;" no-gutters>
                  <v-col cols="6" class="font-weight-bold">
                    {{ sim.nombre.substring(0, 38) }}
                  </v-col>
                  <v-row no-gutters>
                    <v-col cols="4" class="d-flex justify-end mr-4">
                      {{ formatMoney(sim.monto) }}
                    </v-col>
                    <v-col cols="4">
                      Alic: {{ sim.alicuota == 0 || (sim.alicuota % sim.alicuota == 0) ? sim.alicuota : sim.alicuota.toFixed(2) }} %
                    </v-col>
                    <v-col cols="4" class="d-flex justify-end ml-n4">
                      <strong>{{ formatMoney(sim.importe) }}</strong>
                    </v-col>
                  </v-row>
                </v-row>
              </div> 
            </v-tooltip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              color="success"
              title="Seleccionar"
              small
              @click="seleccionarFP(item)"
            >
              fas fa-check
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              color="orange"
              border="left"
              dense
              text
            >
              No hay datos para los filtros seleccionados
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { format_money, order_list_by, roundNumberTF } from '../../../util/utils'
import TextFieldMoney from '../../util/TextFieldMoney.vue'

export default {
  data () {
    return {
      formatMoney: format_money,
      bancos: false,
      monto: null,
      descripcion: '',
      financiaciones: [],
      headers: [
        { text: 'Bancos', value: 'bancos', sortable: false },
        { text: 'Descripción', value: 'descripcion' },
        { text: 'Monto Cuotas', value: 'monto_cuotas', align: 'end' },
        { text: 'Importe Total', value: 'importe_total', align: 'end' },
        { text: '', value: 'simulacion', sortable: false, filterable: false, width: '1' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false, align: 'center' }
      ]
    }
  },
  props: {
    value: Boolean,
    p_monto: Number,
    p_bancos: Array,
    p_financiaciones: Array,
    p_financiaciones_especiales: Array,
    p_formas_pago: Array
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    TextFieldMoney
  },
  watch: {
    dialog (val) {
      if (val) {
        if (this.p_monto) {
          this.monto = JSON.parse(JSON.stringify(this.p_monto))
          this.searchFP()
        }
      } else {
        this.limpiar()
      }
    }
  },
  methods: {
    seleccionarFP (item) {
      if (!this.monto) {
        this.$store.dispatch('show_snackbar', {
          text: 'Ingrese un monto',
          color: 'orange'
        })
        return
      }
      if (parseFloat(this.monto) <= 0) {
        this.$store.dispatch('show_snackbar', {
          text: 'El monto debe ser mayor a 0',
          color: 'orange'
        })
        return
      }
      let existe = this.p_financiaciones.find(fp => fp.forma_pago_id == item.id)
      if (existe) {
        this.$emit('seleccionar', this.monto, existe)
      } else {
        this.$store.dispatch('show_snackbar', {
          text: 'No se encontró la forma de pago seleccionada',
          color: 'orange'
        })
      }
    },
    searchFP () {
      this.financiaciones = []
      if (!this.monto) return

      if (parseFloat(this.monto) <= 0) return

      let financiaciones = this.bancos ? this.p_financiaciones.filter(fp => fp.bancos.length > 0) : this.p_financiaciones

      for (const fin of financiaciones) {
        let bancos_nombre = []
        for (const bp of fin.bancos) {
          let existe = this.p_bancos.find(ba => ba.id == bp && ba.inhabilitado != 1)
          if (existe) {
            bancos_nombre.push(existe.nombre)
          }
        }
        let banco =  bancos_nombre.length > 0 ? bancos_nombre.join(',') : 'TODOS'
        let nombre = fin.solicita_banco == 1 || fin.tarjeta_nombre == fin.cuota_nombre ? fin.forma_pago_nombre : fin.tarjeta_nombre + ' - ' + fin.cuota_nombre
        let total = parseFloat(this.monto) * (1 + (parseFloat(fin.porcentaje) / 100))
        let simulacion = []

        let especiales = this.p_financiaciones_especiales.filter(fe =>
          fe.fp_cod_id == fin.fp_cod_id &&
          fe.fc_cod_id == fin.fc_cod_id &&
          fe.cuota_interna == fin.cuota_interna &&
          fe.tarjeta_codigo == fin.tarjeta_codigo
        )

        if (especiales.length > 0) {
          order_list_by(especiales, 'porcentaje', 'desc')
          let monto_ingresado = parseFloat(this.monto)
          total = 0

          for (const fin_esp of especiales) {
            // si ya no tengo nada mas que agregar entonces quiebro
            if (monto_ingresado == 0) break

            // reviso si el monto que ingresó es mayor o menor que el disponible (importe total de la fin esp - suma de todas esas fin esp en las fp)
            let sumatoria = this.p_formas_pago.filter(fp => fp.forma_pago_id == fin_esp.forma_pago_id).reduce((sum, fp) => sum + fp.monto, 0)
            let monto_disponible = parseFloat(fin_esp.importe_total) - parseFloat(sumatoria)

            // si el monto disponible es negativo => es 0
            if (monto_disponible < 0) monto_disponible = 0

            // si el monto ingresado supera el saldo disponible de la financiacion especial => agrego lo disponible
            if (roundNumberTF(monto_disponible) < roundNumberTF(monto_ingresado)) {
              if (monto_disponible > 0) {
                let importe_especial = monto_disponible * (1 + (parseFloat(fin_esp.porcentaje) / 100))
                total += importe_especial
                simulacion.push({
                  monto: monto_disponible,
                  nombre: fin_esp.forma_pago_nombre,
                  importe: importe_especial,
                  alicuota: fin_esp.porcentaje
                })
                monto_ingresado -= monto_disponible
              }
            }
            // si el monto esta dentro del margen del saldo de la especial => agrego todo
            else {
              let importe_especial = monto_ingresado * (1 + (parseFloat(fin_esp.porcentaje) / 100))
              total += importe_especial
              simulacion.push({
                monto: monto_ingresado,
                nombre: fin_esp.forma_pago_nombre,
                importe: importe_especial,
                alicuota: fin_esp.porcentaje
              })
              monto_ingresado = 0
            }
          }

          // si ya termine de ciclar y todavia me queda parte del monto ingresado => lo agrego a la comunacha
          if (monto_ingresado > 0) {
            let importe_comunacho = monto_ingresado * (1 + (parseFloat(fin.porcentaje) / 100))
            total += importe_comunacho
              simulacion.push({
              monto: monto_ingresado,
              nombre: fin.forma_pago_nombre,
              importe: importe_comunacho,
              alicuota: fin.porcentaje
            })
          }

        }

        this.financiaciones.push({
          id: fin.forma_pago_id,
          bancos: banco,
          descripcion: fin.forma_pago_nombre,
          monto_cuotas: total / parseInt(fin.cuota),
          importe_total: total,
          simulacion: simulacion,
          especial: especiales.length > 0
        })
      }
    },
    limpiar () {
      this.bancos = false
      this.monto = null
      this.descripcion = ''
      this.financiaciones = []
    },
    itemRowBackground (item) {
      return item.especial ? 'background-color: rowsel' : ''
    }
  }
}
</script>