<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!-- FILTROS -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            title="Nueva Venta"
            small
            fab
            @click="openWindow(null)"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel == 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Sucursal
                  <v-autocomplete
                    v-model="filtro.sucursal"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="sucursales"
                    hide-details
                    clearable
                    outlined
                    dense
                    @change="setLocal()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" xl="3" class="py-1 pl-sm-0">
                  Local
                  <v-autocomplete
                    v-model="filtro.local"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="locales_user.filter(loc => loc.sucursal == filtro.sucursal)"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Nº Pedido
                  <v-text-field
                    v-model.trim="filtro.venta"
                    type="number"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col :cols="big ? 12 : 6" :sm="big ? 8 : 3" :md="big ? 5 : 2" class="py-1">
                  Fecha
                  <FechaPickerRango
                    v-model="fechas"
                    :limpiar.sync="clear"
                    @changeSize="changeSize"
                  />
                </v-col>
                <v-col v-if="!big && $vuetify.breakpoint.mdAndUp" cols="2"></v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Factura
                  <v-text-field
                    v-model.trim="filtro.factura"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col v-if="$vuetify.breakpoint.xs" cols="2"></v-col>
                <v-col cols="5" sm="2" xl="1" class="py-1">
                  Cod. Vend.
                  <v-text-field
                    v-model.trim="filtro.vendedor_codigo"
                    type="number"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="7" :sm="big ? 3 : 4" md="2" class="py-1">
                  Vendedor
                  <v-text-field
                    v-model.trim="filtro.vendedor_nombre"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="5" sm="3" md="2" class="py-1">
                  Documento
                  <v-text-field
                    v-model.trim="filtro.documento"
                    type="number"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="7" sm="4" md="2" class="py-1">
                  Cliente
                  <v-text-field
                    v-model.trim="filtro.cliente"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" :sm="big ? 12 : 5" md="2" xl="3" class="py-1 px-0">
                  <BtnFiltro
                    :loading="load"
                    class="pt-1"
                    @clear="limpiar()"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- DATA TABLE -->
      <v-data-table
        class="cebra elevation-2 mt-2"
        :class="permiso_copiar_texto == 1? '' : 'prevent-select'"
        sort-by="id"
        :headers="headers"
        :items="ventas"
        :loading="load"
        :search="search"
        :footer-props="{
          'items-per-page-options': [ 10, 15, 50, 100 ]
        }"
        mobile-breakpoint
        sort-desc
        dense
      >
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="9" xl="10" class="d-flex justify-start align-center" v-if="false">
              <DownloadDataTable
                :data="ventas"
                :name="`Ventas ${moment(new Date()).format('YYYYMMDD HHmmss')}`"
                :headers="{
                  'Id': 'id',
                  'Fecha': 'fecha',
                  'Importe': 'importe',
                  'Vend. Nombre': 'vendedor_nombre',
                  'Cliente': 'cliente',
                  'Teléfono': 'telefono',
                  'Documento': 'documento',
                  'Nº Factura': 'factura'
                }"
              />
            </v-col>
            <v-col cols="6" sm="3" xl="2">
              <SearchDataTable
                v-model="search"
              />
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.importe`]="{ value }">
          {{ formatMoney(value) }}
        </template>
        <template v-slot:[`item.fecha`]="{ value }">
          {{ value ? moment(value).format('DD/MM/YYYY') : null }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            color="info"
            title="Ver"
            class="mr-2"
            small
            @click="openWindow(item.id)"
          >
            fas fa-eye
          </v-icon>
          <v-icon
            title="Factura"
            color="cyan"
            class="mr-2"
            :disabled="item.factura_bloqueada == 1"
            small
            @click="botonFactura(item)"
          >
            fas fa-print
          </v-icon>
          <v-icon
            title="Remito"
            color="indigo"
            small
            @click="botonRemito(item)"
          >
            fas fa-receipt
          </v-icon>
          <v-icon
            v-if="item.con_gift == 1 && permiso_enviar_gf == 1"
            title="Ver Cupones"
            color="success"
            class="ml-2"
            small
            @click="vta_item = item; dialog_gf = true"
          >
            fas fa-gift
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="info"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <!-- COMPONENTES -->
    <PDFViewer
      v-model="pdf_dialog"
      :pdf="pdf"
      :nombre="pdf_nombre"
      :titulo="pdf_nombre"
    />
    <ModalSendGF
      v-model="dialog_gf"
      :p_venta="vta_item"
    />
    <!-- modal de opciones de impresion -->
    <v-dialog
      v-model="dialog_opciones"
      width="400"
    >
      <v-card>
        <v-card-title>
          Seleccione una acción
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="dialog_opciones = false"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6">
              <v-hover v-slot="{ hover }">
                <v-card
                  style="cursor: pointer"
                  class="pa-3"
                  :class="hover ? 'white--text' : ''"
                  :elevation="hover ? '10' : ''"
                  :color="hover ? 'primary' : ''"
                  @click="accion == 'factura' ? imprimirFactura(vta_item) : getRemito(vta_item, 1)"
                >
                  <v-icon
                    :color="hover ? 'white' : 'primary'"
                    left
                  >
                    fas fa-print
                  </v-icon>
                  Imprimir
                </v-card>
              </v-hover>
            </v-col>
            <v-col cols="12" sm="6">
              <v-hover v-slot="{ hover }">
                <v-card
                  style="cursor: pointer"
                  class="pa-3"
                  :class="hover ? 'white--text' : ''"
                  :elevation="hover ? '10' : ''"
                  :color="hover ? 'primary' : ''"
                  @click="accion == 'factura' ? descargarFactura(vta_item) : getRemito(vta_item, 0)"
                >
                  <v-icon
                    :color="hover ? 'white' : 'primary'"
                    left
                  >
                    fas fa-eye
                  </v-icon>
                  Visualizar
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import moment from 'moment'
import download from 'downloadjs'
import * as JSPM from 'jsprintmanager'
import { mapState, mapGetters } from 'vuex'
import { format_money, format_date, ArrayBufferToBase64 } from '../../util/utils'
import { facturaTermica, facturaA4, remitoTermica } from '../../util/plantillas/pdfs'
import BtnFiltro from '../../components/util/BtnFiltro.vue'
import SearchDataTable from '../../components/util/SearchDataTable.vue'
import DownloadDataTable from '../../components/util/DownloadDataTable.vue'
import FechaPickerRango from '../../components/util/FechaPickerRango.vue'
import ModalSendGF from './../../components/ventas/venta/ModalSendGF.vue'
import PDFViewer from '../../util/plantillas/PDFViewer.vue'

export default {
  data () {
    return {
      moment: moment,
      formatMoney: format_money,
      panel: 0,
      permiso_copiar_texto :0,
      permiso_descargar: 0,
      permiso_enviar_gf: 0,
      dialog_opciones: false,
      dialog_gf: false,
      pdf_dialog: false,
      pdf_nombre: '',
      pdf: null,
      big: false,
      load: false,
      clear: false,
      vta_item: {},
      accion: '',
      search: '',
      headers: [
        { text: 'Id', value: 'id', cellClass: 'font-weight-bold' },
        { text: 'Fecha', value: 'fecha' },
        { text: 'Importe', value: 'importe', align: 'end' },
        { text: 'Vend. Nombre', value: 'vendedor_nombre', cellClass: 'font-weight-bold' },
        { text: 'Cliente', value: 'cliente' },
        { text: 'Teléfono', value: 'telefono' },
        { text: 'Documento', value: 'documento' },
        { text: 'Nº Factura', value: 'factura' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false }
      ],
      ventas: [],
      fechas: [ null, null ],
      filtro: {
        sucursal: null,
        local: null,
        vendedor_codigo: null,
        vendedor_nombre: null,
        documento: null,
        cliente: null,
        factura: null,
        venta: null,
        desde: null,
        hasta: null
      }
    }
  },
  async created () {
    // obtengo los datos necesarios
    this.$store.state.loading = true
    await this.$store.dispatch('vendedores/get_locales_user')
    let res_permiso = await this.$store.dispatch('ventas/get_permisos_user')
    this.$store.state.loading = false

    // si tiene solo 1 sucursal se la selecciono
    if (this.una_sucursal) {
      this.filtro.sucursal = this.sucursales[0].id
      // si tiene favorito lo autoselecciono
      let favorito = this.locales_user.filter(lu => lu.favorito == 1)
      if (favorito.length == 1) {
        this.filtro.local = favorito[0].id
      }
    }
    if (res_permiso.exito == 1) {
      this.permiso_descargar    = res_permiso.permisos.descargar_factura
      this.permiso_enviar_gf    = res_permiso.permisos.enviar_gf
      this.permiso_copiar_texto = res_permiso.permisos.copiar_seleccionar;
    } else {
      this.$store.dispatch('show_snackbar', {
        text: res_permiso.message,
        color: 'error'
      })
    }
  },
  mounted () {
    this.JSPMInit()
  },
  computed: {
    ...mapState('vendedores', ['locales_user']),
    ...mapState(['sucursales']),
    ...mapGetters(['una_sucursal'])
  },
  components: {
    BtnFiltro,
    SearchDataTable,
    DownloadDataTable,
    FechaPickerRango,
    ModalSendGF,
    PDFViewer
  },
  methods: {
    botonRemito (venta) {
      // si tiene permiso lo dejo elegir la accion
      if (this.permiso_descargar == 1) {
        this.accion = 'remito'
        this.vta_item = venta
        this.dialog_opciones = true
      }
      // si no tiene permiso mando dependiendo de la impresion automatica
      else {
        this.getRemito(venta, venta.impresion_automatica == 1)
      }
    },
    async getRemito (venta, imprimir) {
      this.dialog_opciones = false
      this.$store.state.loading = true
      let result_re = await this.$store.dispatch('ventas/get_remito', venta.id)
      this.$store.state.loading = false

      if (result_re.exito == 1) {
        this.pdf = await remitoTermica(result_re.data)
        if (imprimir == 1) {
          this.doPrintPDF(this.pdf)
        } else {
          this.pdf_nombre = `Remito N° 0000-${result_re.data.id_remito.toString().padStart(8, '0')}`
          this.pdf_dialog = true
        }
      } else {
        this.$swal.fire({
          icon: 'error',
          title: result_re.message
        })
      }
    },
    botonFactura (venta) {
      // si no tiene cae paso a facturar
      if (venta.cae == 0) {
        this.facturar(venta)
        return
      }

      // si tiene permiso lo dejo elegir la accion
      if (this.permiso_descargar == 1) {
        this.accion = 'factura'
        this.vta_item = venta
        this.dialog_opciones = true
      }
      // si no tiene permiso comeinzo con las validaciones para ver q accion realizar
      else {
        if (venta.impresion_automatica == 1) {
          this.imprimirFactura(venta)
        } else {
          this.descargarFactura(venta)
        }
      }
    },
    async descargarFactura (venta) {
      this.dialog_opciones = false
      this.$store.state.loading = true
      const datos = await this.getDatosFactura(venta.comprobante_caja_id)
      this.$store.state.loading = false
      if (datos == null) return

      this.$store.state.loading = true
      if (datos.encabezado.impresora_termica == 1) {
        this.pdf = await facturaTermica(datos)
      } else {
        this.pdf = await facturaA4(datos)
      }
      this.$store.state.loading = false

      this.pdf_nombre = `${datos.encabezado.referencia}-${datos.encabezado.emynum}`
      this.pdf_dialog = true
    },
    async imprimirFactura (venta) {
      this.dialog_opciones = false
      this.$store.state.loading = true
      const datos = await this.getDatosFactura(venta.comprobante_caja_id)
      this.$store.state.loading = false
      if (datos == null) return

      this.$store.state.loading = true
      if (datos.encabezado.impresora_termica == 1) {
        this.doPrintPDF(await facturaTermica(datos))
      } else {
        this.doPrintPDF(await facturaA4(datos))
      }
      this.$store.state.loading = false

      venta.factura_bloqueada = 1
      setTimeout(() => (venta.factura_bloqueada = 0), 10000)
    },
    async getDatosFactura (comp_id) {
      this.$store.state.loading = true
      let respuesta = await this.$store.dispatch('caja/datos_factura', { comp_id: comp_id })
      this.$store.state.loading = false
      if (respuesta.exito == 1) {
        return respuesta.datos
      } else {
        this.$swal.fire({
          icon: 'error',
          title: respuesta.message
        })
        return null
      }
    },
    async facturar (venta) {
      this.$store.state.loading = true
      let respuesta = await this.$store.dispatch('afip/permiteFacturar', {
        comprobante_caja_id: venta.comprobante_caja_id
      })
      this.$store.state.loading = false

      if (respuesta.resultado != 1) {
        return this.$swal.fire({
          icon: 'error',
          title: respuesta.msj
        })
      }
      
      let modal = await this.$swal.fire({
        icon: 'info',
        html: `<h2>¿Está seguro de emitir un comprobante del tipo: <strong>${respuesta.comprobante_tipo}</strong> para el pedido Nº ${venta.id}?</h2>`,
        confirmButtonText: 'Facturar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      })
      if (!modal.isConfirmed) return

      this.$store.state.loading = true
      let fcPeticion = await this.$store.dispatch('afip/getFactura', {
        comprobante_caja_id: venta.comprobante_caja_id
      })
      this.$store.state.loading = false

      if (fcPeticion.resultado == 1) {
        this.$swal.fire({
          icon: 'success',
          title: 'Se generó con Éxito la Factura',
          text: 'En breve se descargará el comprobante.',
          timer: 3000
        })

        this.$store.state.loading = true
        const datos = await this.getDatosFactura(venta.comprobante_caja_id)
        this.$store.state.loading = false

        if (datos != null) {
          this.$store.state.loading = true
          if (datos.encabezado.impresora_termica == 1) {
            this.pdf = await facturaTermica(datos)
          } else {
            this.pdf = await facturaA4(datos)
          }
          this.$store.state.loading = false

          if (venta.impresion_automatica == 1) {
            this.doPrintPDF(this.pdf)
          } else {
            download(this.pdf, `${datos.encabezado.referencia}-${datos.encabezado.emynum}.pdf`,'application/pdf')
          }
        }

      } else {
        this.$swal.fire({
          icon: 'error',
          title: 'Error al generar la Factura',
          text: fcPeticion.msj
        })
      }
    },
    async buscar () {
      this.ventas = []
      this.search = ''
      this.filtro.desde = format_date(this.fechas[0])
      this.filtro.hasta = format_date(this.fechas[1])

      this.load = true
      let result = await this.$store.dispatch('ventas/get_ventas', this.filtro)
      this.load = false

      if (result.exito == 1) {
        this.ventas = result.data
      } else {
        this.$swal.fire({
          icon: 'warning',
          title: result.message
        })
      }
    },
    setLocal () {
      let locales = this.locales_user.filter(loc => loc.sucursal == this.filtro.sucursal)
      this.filtro.local = locales.length == 1 ? locales[0].id : null
    },
    openWindow (id_venta) {
      const path = id_venta == null ? '/nueva-venta' : `/venta-salon/${id_venta}`
      const routeData = this.$router.resolve({ path: path })
      window.open(routeData.href, '_blank')
    },
    changeSize (custom) {
      this.big = custom
    },
    limpiar () {
      this.clear = true
      this.fechas = [ null, null ]
      this.filtro = {
        sucursal: null,
        local: null,
        vendedor_codigo: null,
        vendedor_nombre: null,
        documento: null,
        cliente: null,
        factura: null,
        venta: null,
        desde: null,
        hasta: null
      }
    },
    JSPMInit () {
      let _this = this
      JSPM.JSPrintManager.auto_reconnect = true
      JSPM.JSPrintManager.start()
      JSPM.JSPrintManager.WS.onStatusChanged = function () {
        _this.getPrinters().then((p) => {
          _this.printers = p
          _this.$nextTick()
        })
      }
    },
    getPrinters () {
      return new Promise((ok, err) => {
        let printers = []
        if(JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
          JSPM.JSPrintManager.getPrinters().then(function (myPrinters) {
            printers = myPrinters
            //console.log(printers)
            ok(printers)
          }).catch((e) => err(e))
        } else {
          console.warn("JSPM WS not open")
          ok(printers)
        }
      })
    },
    JSPMStatus () {
      if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
        return true
      }
      else if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Closed) {
        this.$swal.fire({
          icon: 'warning',
          title: 'JSPrintManager no se está ejecutando en esta máquina',
          text: 'Por favor instale los drivers proporcionados'
        })
        return false
      }
      else if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Blocked) {
        this.$swal.fire({
          icon: 'error',
          title: 'JSPM a bloqueado este sitio!'
        })
        return false
      }
    },
    doPrintPDF (file) {
      if (this.JSPMStatus()) {
        let cpj = new JSPM.ClientPrintJob()
        cpj.clientPrinter = new JSPM.DefaultPrinter()
        let my_file = new JSPM.PrintFilePDF(ArrayBufferToBase64(file), JSPM.FileSourceType.Base64, 'MyFile.pdf', 1)
        cpj.files.push(my_file)
        cpj.sendToClient()
      }
    }
  }
}
</script>
<style scoped>
  .prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
</style>