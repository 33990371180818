<template>
  <v-dialog
    v-model="dialog"
    max-width="700"
    :persistent="editar || load"
    scrollable
  >
    <v-card :disabled="load">
      <v-card-title>
        {{ nueva ? 'Nueva a' : editar ? 'Editar a' : 'A' }}utorización {{ !nueva ? `Nº ${p_excepcion.id}` : '' }}
        <v-spacer></v-spacer>
        <template v-if="!nueva && p_excepcion.inhabilitado != 1">
          <v-switch
            v-model="editar"
            label="Editar"
            class="d-flex align-top mt-0"
            hide-details
            dense
          ></v-switch>
          <v-divider
            class="mx-3"
            vertical
          ></v-divider>
        </template>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-8">
        <v-form ref="form">
          <v-row>
            <v-col cols="12" sm="6" class="py-0">
              <v-autocomplete
                v-model="vendedor"
                label="Vendedor"
                item-text="nombre"
                :items="vendedores"
                :readonly="!editar"
                :filled="!editar"
                :clearable="editar"
                return-object
                outlined
                dense
                @change="setUsername()"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" sm="3" class="py-0">
              <v-text-field
                v-model.trim="excepcion.username"
                label="Usuario"
                :readonly="!editar || (vendedor != null && Object.keys(vendedor).length > 0)"
                :filled="!editar || (vendedor != null && Object.keys(vendedor).length > 0)"
                :rules="editar ? [rules.required] : []"
                validate-on-blur
                outlined
                dense
                @change="validarUsuario()"
              ></v-text-field>
            </v-col>
            <v-col v-if="editar" cols="6" sm="3" class="py-0">
              <v-text-field
                v-model.trim="articulo"
                label="Artículo Base"
                type="number"
                tabindex="1"
                hide-details
                clearable
                outlined
                dense
                @change="getFinanciaciones()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-autocomplete
                v-model="excepcion.fp_cond_id"
                label="Forma de Pago"
                item-text="forma_pago_nombre"
                item-value="forma_pago_id"
                :items="articulo ? financiaciones : all_financiaciones"
                :readonly="!editar"
                :filled="!editar"
                :rules="editar ? [rules.required] : []"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" sm="3" class="py-0">
              <v-text-field
                v-model.trim="excepcion.documento"
                label="N° Documento"
                type="number"
                :readonly="!editar"
                :filled="!editar"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col v-if="!nueva && excepcion.venta_id" cols="6" sm="3" class="py-0">
              <v-text-field
                v-model.trim="excepcion.venta_id"
                label="N° Pedido"
                type="number"
                readonly
                filled
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col v-if="!nueva" cols="6" sm="3" class="py-0">
              <v-checkbox
                v-model="excepcion.inhabilitado"
                label="Inhabilitado"
                class="mt-0"
                :true-value="1"
                :false-value="0"
                :disabled="!editar"
                hide-details
                dense
              ></v-checkbox>
            </v-col>
            <v-col cols="12" class="py-0">
              <ArticulosActivos
                v-model="articulos"
                :disabled="!editar"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          class="mr-3"
          :color="editar ? 'error' : 'secondary'"
          :disabled="load"
          @click="dialog = false"
        >
          {{ editar ? 'Cancelar' : 'Volver' }}
        </v-btn>
        <BtnConfirmar
          v-if="editar"
          :loading="load"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { order_list_by } from '../../util/utils'
import BtnConfirmar from '../util/BtnConfirmar.vue'
import ArticulosActivos from '../util/ArticulosActivos.vue'

export default {
  data () {
    return {
      load: false,
      nueva: false,
      editar: false,
      articulo: null,
      financiaciones: [],
      all_financiaciones: [],
      vendedores: [],
      articulos: [],
      excepcion: {},
      vendedor: {},
      rules: {
        required: value => !!value || 'Campo requerido'
      }
    }
  },
  props: {
    value: Boolean,
    p_financiaciones: Array,
    p_excepcion: Object
  },
  computed: {
    ...mapState('financiaciones', ['confUsuario']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar,
    ArticulosActivos
  },
  watch: {
    async dialog (val) {
      await new Promise(resolve => setTimeout(resolve, 1))
      this.all_financiaciones = [...this.p_financiaciones]
      if (val) {
        await this.getVendedores()
        if (Object.keys(this.p_excepcion).length > 0) {
          this.setOriginal()
        } else {
          this.nueva = true
          this.editar = true
        }
      } else {
        this.nueva = false
        this.editar = false
        this.articulo = null
        this.vendedor = {}
        this.excepcion = {}
        this.articulos = []
        this.financiaciones = []
        this.$refs.form.resetValidation()
      }
    },
    vendedor: {
      handler: function (val) {
        if (val == null) this.vendedor = {}
      },
      deep: true
    },
    editar (val) {
      if (!val && this.dialog) {
        this.setOriginal()
      }
    }
  },
  methods: {
    async guardar () {
      // validaciones previas
      if (!this.$refs.form.validate()) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Complete los campos requeridos',
          color: 'orange'
        })
      }
      if (this.articulos.length == 0) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Debe ingresar al menos un artículo',
          color: 'orange'
        })
      }
      // NUEVA EXCEPCION
      if (this.nueva) {
        let datos = JSON.parse(JSON.stringify(this.excepcion))
        datos.articulos = this.articulos.map(art => art.codigo)

        this.load = true
        let result = await this.$store.dispatch('financiaciones/nueva_excepcion', datos)
        this.load = false

        if (result.exito == 1) {
          this.dialog = false
          this.$swal.fire({
            icon: 'success',
            title: result.message
          })
        } else {
          this.$store.dispatch('show_snackbar', {
            text: result.message,
            color: 'error'
          })
        }
      }
      // EDITAR EXCEPCION
      else {
        // armo mi array de articulos
        let articulos = []
        let eliminados = this.excepcion.articulos.filter(art => !this.articulos.map(at => at.codigo).includes(art.codigo))
        let nuevos = this.articulos.filter(art => !this.excepcion.articulos.map(at => at.codigo).includes(art.codigo))
        for (const articulo of eliminados) {
          articulos.push({
            codigo: articulo.codigo,
            accion: 'DELETE'
          })
        }
        for (const articulo of nuevos) {
          articulos.push({
            codigo: articulo.codigo,
            accion: 'INSERT'
          })
        }

        let datos = JSON.parse(JSON.stringify(this.excepcion))
        datos.articulos = articulos

        this.load = true
        let result = await this.$store.dispatch('financiaciones/editar_excepcion', datos)
        this.load = false

        if (result.exito == 1) {
          this.$emit('actualizar', datos, this.articulos)
          this.dialog = false
          this.$store.dispatch('show_snackbar', {
            text: result.message,
            color: 'success'
          })
        } else {
          this.$store.dispatch('show_snackbar', {
            text: result.message,
            color: 'error'
          })
        }
      }
    },
    async validarUsuario () {
      if (!this.excepcion.username) return
      this.$store.state.loading = true
      let result = await this.$store.dispatch('financiaciones/validar_usuario', this.excepcion.username)
      this.$store.state.loading = false

      if (result.exito == 1) {
        this.excepcion.username = this.excepcion.username.toUpperCase()
        this.setVendedor()
      } else {
        this.excepcion.username = null
        this.vendedor = {}
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }
    },
    async getFinanciaciones () {
      this.financiaciones = []
      this.excepcion.fp_cond_id = null
      if (!this.articulo) return
      this.$store.state.loading = true
      await this.$store.dispatch('financiaciones/get_financiaciones_condcom', {
        articulo: this.articulo,
        especial: 1,
        empresas: this.confUsuario.empresas,
        locales: this.confUsuario.locales,
        sucursales: this.confUsuario.sucursales,
        canales: this.confUsuario.canales
      })
        .then((res) => {
          this.financiaciones = res.formas_pago_habilitadas
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
      order_list_by(this.financiaciones, 'forma_pago_nombre')
    },
    async getVendedores () {
      if (this.vendedores.length > 0) return
      let vendedores = []
      this.$store.state.loading = true
      await this.$store.dispatch('vendedores/get_vendedores', {
        empresa: 2,
        inhabilitado: 0,
        categoria: 5
      })
        .then((data) => {
          vendedores = data.vendedores
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
      for (const vendedor of vendedores) {
        if (vendedor.usuario) {
          this.vendedores.push(vendedor)          
        }
      }
      order_list_by(this.vendedores, 'nombre')
    },
    setVendedor () {
      // busco si tiene vendedor ese usuario
      let vendedor = this.vendedores.find(vd => vd.usuario == this.excepcion.username)
      if (vendedor) {
        this.vendedor = vendedor
      }
    },
    setUsername () {
      if (this.vendedor != null && Object.keys(this.vendedor).length > 0) {
        this.excepcion.username = this.vendedor.usuario
        this.validarUsuario()
      } else {
        this.excepcion.username = null
      }
    },
    setOriginal () {
      this.excepcion = JSON.parse(JSON.stringify(this.p_excepcion))
      this.validarFP()
      this.articulos = [...this.excepcion.articulos]
      this.setVendedor()
    },
    validarFP () {
      let existe = this.all_financiaciones.find(fp => fp.forma_pago_id == this.excepcion.fp_cond_id)
      if (!existe) {
        this.all_financiaciones.push({
          forma_pago_id: this.excepcion.fp_cond_id,
          forma_pago_nombre: this.excepcion.descripcion
        })
      }
    }
  }
}
</script>