<template>
  <v-dialog
    v-model="dialog"
    max-width="700"
    persistent
    scrollable
  >
    <v-card :disabled="load">
      <v-card-title>
        Cargar cierre de lote
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pb-sm-4 pb-0">
        <v-form ref="form">
          <v-row class="pt-8">
            <v-col cols="12" sm="3" class="py-0">
              <v-autocomplete
                v-model="sucursal"
                label="Sucursal"
                item-text="nombre"
                item-value="id"
                :items="sucursales"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
                @change="local = null; terminal = null; terminales = []"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-autocomplete
                v-model="local"
                label="Local"
                item-text="nombre"
                item-value="id"
                :items="locales.filter(loc => loc.sucursal == sucursal && loc.empresa == 2)"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
                @change="get_terminales()"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" sm="3" class="py-0">
              <v-autocomplete
                v-model="terminal"
                label="Terminal"
                item-text="nombre"
                item-value="numero"
                :items="terminales"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
                @change="get_lote()"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" sm="3" class="py-0">
              <FechaPicker
                v-model="fecha"
                label="Fecha"
              />
            </v-col>
            <v-col cols="6" sm="2" class="py-0">
              <v-text-field
                v-model.trim="lote"
                label="Lote"
                type="number"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="7" class="py-0">
              <v-file-input
                v-model="archivo"
                label="Imagen"
                accept="image/jpeg, image/png, image/webp"
                prepend-icon="fas fa-camera"
                :truncate-length="100"
                :rules="[rules.archivo]"
                :clearable="false"
                outlined
                dense
              >
              </v-file-input>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          :disabled="load"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          clase="ml-3"
          :loading="load"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { tipos_archivos, getBase64 } from '../../util/utils'
import BtnConfirmar from '../util/BtnConfirmar'
import FechaPicker from '../util/FechaPicker'
import moment from 'moment'

export default {
  data () {
    return {
      load: false,
      archivo: null,
      sucursal: null,
      local: null,
      terminal: null,
      lote: null,
      fecha: moment(new Date()).format('DD/MM/YYYY'),
      extension: null,
      terminales: [],
      rules: {
        required: value => !!value || 'Campo requerido',
        archivo: file => {
          if (file) {
            const tipos_permitidos = ['.png', '.jpg', '.webp']
            const max_size = 6

            // busco en el array de tipos_archivos el tipo de archivo del archivo que subio
            const tipo_archivo = tipos_archivos.find(a => a.mime === file.type)

            if (tipo_archivo) {
            // si encuentro el tipo de archivo paso a validar que pertenezca a por lo menos un tipo de extension existente en el prop de tipos
              if (tipos_permitidos.find(a => a === tipo_archivo.extension)) {
                // la extension del tipo del archivo existe en los tipos validos
                // valido el pesp del archivo (en MB)
                if (file.size <= max_size * 1024 * 1024) {
                  this.extension = file.name.substring(file.name.lastIndexOf('.'), file.name.length)
                  return true
                }
                else {
                  // excedio el peso
                  this.extension = null
                  return `El archivo no debe pesar más de ${max_size}MB`
                }
              }
              else {
                // subio un archivo con una extension no valida
                this.extension = null
                return 'Tipo de archivo no válido'
              }
            }
            else {
              // si no lo encuentra es porque subio un tipo de archivo que no esta registrado en el array tipos_archivos
              this.extension = null
              return 'Tipo de archivo no válido para el sistema'
            }
          }
          else {
            this.extension = null
            return 'Debe subir una foto del cupón'
          }
        }
      }
    }
  },
  props: {
    value: Boolean,
    local_codigo: {
      type: Number,
      default: null
    }
  },
  computed: {
    ...mapState(['sucursales', 'locales']),
    ...mapGetters(['una_sucursal']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar,
    FechaPicker
  },
  watch: {
    async dialog (val) {
      if (val) {
        // si envio el codigo de local, esta cargando desde caja
        if (this.local_codigo != null) {
          // obtengo la sucursal del local
          let local = this.locales.find(loc => loc.id == this.local_codigo)
          if (local && local.sucursal != null) {
            this.sucursal = local.sucursal
            this.local = await JSON.parse(JSON.stringify(this.local_codigo))
            await this.get_terminales()
          }
        } else {
          // si tiene solo 1 sucursal la selecciono
          if (this.una_sucursal) this.filtro.sucursal = this.sucursales[0].id
        }
      } else {
        this.$refs.form.resetValidation()
        this.archivo = null
        this.sucursal = null
        this.local = null
        this.terminal = null
        this.lote = null
        this.fecha = moment(new Date()).format('DD/MM/YYYY')
      }
    }
  },
  methods: {
    async guardar () {
      if (this.$refs.form.validate()) {
        if (!this.fecha) {
          return this.$store.dispatch('show_snackbar', {
            text: 'Debe ingrsar la fecha del cierre de lote',
            color: 'orange'
          })
        }
        this.$store.state.loading = true
        await this.$store.dispatch('laposIntegrado/cargar_cierre_lote', {
          terminal: this.terminal,
          fecha: this.fecha,
          lote: this.lote
        })
          .then(async (result) => {
            // si cargo bien el cierre, procedo a subir la imagen del cupon
            let base46 = ''
            await getBase64(this.archivo).then(data => {
              base46 = data.split(',')[1]
            })
            await this.$store.dispatch('upload_file', {
              file: base46,
              name: result.id + this.extension,
              path: '/cupones_cierre_lote/' + this.terminal,
              raiz: 1,
              tabla: 7,
              id: result.id
            })
              .then(() => {
                this.$swal.fire({
                  icon: 'success',
                  title: result.message
                })
              })
              .catch((err) => {
                this.$swal.fire({
                  icon: 'warning',
                  title: 'Se cargó correctamente el cierre de lote pero no se pudo subir la imagen del cupón',
                  text: err.message
                })
              })
              this.dialog = false
          })
          .catch((err) => {
            this.$store.dispatch('show_snackbar', {
              text: err.message,
              color: 'error'
            })
          })
        this.$store.state.loading = false
      }
    },
    async get_lote () {
      if (this.terminal) {
        this.$store.state.loading = true
        this.lote = await this.$store.dispatch('laposIntegrado/get_next_lote', this.terminal)
        this.$store.state.loading = false
      }
    },
    async get_terminales () {
      this.terminal = null
      this.terminales = []
      if (this.local) {
        this.$store.state.loading = true
        await this.$store.dispatch('localesStore/getTerminales', this.local)
          .then((res) => {
            for (const terminal of res.data) {
              if (terminal.activa) {
                this.terminales.push({
                  id: terminal.id,
                  numero: terminal.numero,
                  nombre: terminal.integrada ? terminal.numero.toString() : `${terminal.numero} (desint.)`,
                  integrada: terminal.integrada ? 1 : 0
                })
              }
            }
            this.terminales_copy = JSON.parse(JSON.stringify(this.terminales))
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        this.$store.state.loading = false
      }
    }
  }
}
</script>